import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
    <svg viewBox="0 0 1448 222" height={35}>
      <style />
      <path
        id="prefix__WLSN"
        fill={fill}
        d="M366.6 190.8L308.4 39.2h27.2l34 97.2 24-61.8-13.8-35.4h23l37.4 94.4 31.2-94.4h25l-56.8 151.2-34.4-86.6-38.6 87zm157.2-6.8V38.4h23.4v123.2H619V184h-95.2zM735 50.2L726.2 68q-7.8-4-16.6-6.8-8.6-3-16.8-3-10.8 0-17.2 4.8-6.2 4.6-6.2 13.2 0 6 4.4 10.6 4.4 4.4 11.2 7.8 6.8 3.4 14.4 6.2 8.8 3.2 17 8 8.4 4.8 13.8 13.2 5.4 8.2 5.4 22 0 11.4-5.8 21-5.8 9.4-16.6 15t-26.2 5.6q-13.6 0-26.4-4.2-12.6-4.4-22.4-11.2l10.4-18.2q7.2 5.4 17.2 9.2 10.2 3.8 19 3.8 6.2 0 12.2-2 6.2-2 10.2-6.6 4.2-4.6 4.2-12.2 0-6.2-3.6-10.6-3.6-4.4-9.4-7.6-5.6-3.4-12.4-6-6.8-2.6-14-5.8-7-3.2-13-7.8-6-4.8-9.8-11.6-3.8-6.8-3.8-16.8 0-12 5.6-20.8 5.6-9 15.4-14 10-5 23-5.6 15.4 0 26.2 3.8 11 3.6 19.4 8.8zm159.8 139h-.6l-105-101.8.6 96.6h-23.6V33.4h1.2L872 136.6l-.4-98.2h23.2v150.8z"
      />
      <path
        id="prefix__BLOG"
        fill={fill}
        d="M933.8 41.6h27.8q19.8 0 30.4 8.4 10.6 8.2 10.6 27.4 0 11.2-5.4 21-5.2 9.6-17.2 13 8 1.8 14.8 6.6 6.8 4.6 11 11.6t4.2 16.2q0 12-6.2 20-6 8-15.8 12.2-9.8 4-20.8 4h-33.4V41.6zm4 68.2h28.8q17 0 24.4-9 7.6-9.2 7.6-23.2 0-17.4-10-24.8-10-7.4-26.8-7.4h-24v64.4zm0 68.6h29q10.6 0 19.4-3.6 9-3.6 14.4-10.6 5.4-7.2 5.4-17.8 0-11.4-6-18.6-6-7.4-15-10.8-9-3.4-18.2-3.4h-29v64.8zm110.6 3.6V41.6h4v136.6h80.4v3.8h-84.4zm95.4-70q0-14.8 5.6-27.8 5.6-13.2 15.4-23 9.8-10 22.8-15.6t27.8-5.6q14.8 0 27.8 5.6t22.8 15.6q10 9.8 15.6 23 5.8 13 5.8 27.8t-5.8 27.8q-5.6 13-15.6 22.8-9.8 9.8-22.8 15.4t-27.8 5.6q-14.8 0-27.8-5.2-13-5.4-22.8-15t-15.4-22.6q-5.6-13.2-5.6-28.8zm4 .2q0 14 5.2 26.4 5.2 12.2 14.4 21.6 9.2 9.2 21.4 14.4 12.2 5.2 26 5.2 14.2 0 26.6-5.2 12.4-5.2 21.8-14.4 9.4-9.4 14.8-21.6 5.4-12.4 5.4-26.6 0-14-5.4-26.2-5.4-12.4-14.8-21.8-9.2-9.4-21.6-14.8-12.2-5.4-26.2-5.4-14.4 0-26.8 5.6-12.2 5.4-21.4 15t-14.4 22q-5 12.2-5 25.8zm281.6 9v52.2q-8 4.6-19.4 7.4-11.4 2.8-21.6 2.8-16.2 0-29.6-5.4-13.2-5.6-22.6-15.4-9.4-9.8-14.4-22.8t-5-27.8q0-15 5.6-28 5.6-13.2 15.4-23 9.8-10 22.8-15.6t27.8-5.6q10.2 0 19.4 2.8 9.4 2.6 17.6 7.4l-2 3.4q-7.6-4.6-16.6-7.2-9-2.6-18.2-2.6-14.2 0-26.6 5.4-12.2 5.4-21.6 14.8-9.2 9.4-14.4 21.8-5.2 12.4-5.2 26.4 0 14.2 4.6 26.4 4.8 12.2 13.6 21.6 8.8 9.2 21.2 14.4 12.4 5.2 27.8 5.2 9 0 19.4-2.2 10.4-2.4 18-6.8V125h-34v-3.8h38z"
      />
      <path
        id="prefix__&lt;"
        fill={fill}
        d="M89.12 183.13v-33.74L46.3 107.26v-1.01l42.82-42.12V30.38L26.75 93.82v25.88z"
      />
      <path
        id="prefix__&gt;"
        fill={fill}
        d="M219.88 29.87v33.74l42.82 42.13v1.01l-42.82 42.12v33.75l62.37-63.44V93.3z"
      />
      <path
        id="prefix__/"
        fill={fill}
        d="M97.44 198.68h30.93l82.7-177.69h-30.92z"
      />
    </svg>
  );
}
